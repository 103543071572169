import { Home } from './pages/Home';
import { Navbar } from "./pages/components/Navbar";
import { Footer } from "./pages/components/Footer";
import { Selector } from "./pages/forms/Selector";
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Moderator } from './pages/forms/Moderator';
import { MediaTeam } from './pages/forms/MediaTeam';
import { Collab } from './pages/forms/Collab';
import { TeamSelector } from "./pages/team/TeamSelector";
import { ModeratorTeam } from './pages/team/ModeratorTeam';
import { ArtistTeam } from './pages/team/ArtistTeam';
import { Live } from './pages/Live';
import { NotFound } from './pages/404';
import { CommSelector } from "./pages/commissions/CommSelector";
import { useState } from 'react';
import axios from 'axios';
import { Discord, Twitch, Twitter, YouTube } from './pages/Links';
import { Website } from './pages/commissions/Website';
import { Bot } from './pages/commissions/Bot';
import { TwitchLogin } from './pages/login/TwitchLogin';
import { Login } from './pages/login/Login';
import { DiscordLogin } from './pages/login/DiscordLogin';
import { TwitterLogin } from './pages/login/TwitterLogin';


function App() {
  const [live, setLive] = useState();
  const [vods, setVods] = useState();
  const [info, setInfo] = useState();

  let sessionToken = window.localStorage.getItem("token") ? window.localStorage.getItem("token") : window.sessionStorage.getItem("token");

  if (sessionToken && info === undefined) {
    getInfo(sessionToken, setInfo);
  }


  if (live === undefined) {
    checkIfLive(live, setLive, vods, setVods);
  }

  if (vods !== undefined) {
    return (
      <BrowserRouter>
        <Navbar live={live} info={info}/>
        <div>
          <Routes>
            <Route exact="true" path="/" element={<Home vod={vods[0]} />} />
            <Route path="applications" element={<Selector />}>
              <Route index element={<Moderator />} />
              <Route path="moderator" element={<Moderator />} />
              <Route path="media" element={<MediaTeam />} />
              <Route path="collab" element={<Collab />} />
            </Route>
            <Route path="team" element={<TeamSelector />}>
              <Route index element={<ModeratorTeam />} />
              <Route path="moderators" element={<ModeratorTeam />} />
              <Route path="artists" element={<ArtistTeam />} />
            </Route>
            <Route path="commission" element={<CommSelector />}>
              <Route index element={<Bot />} />
              <Route path="bot" element={<Bot />} />
              <Route path="website" element={<Website />} />
            </Route>
            <Route path="live" element={<Live live={live} vods={vods} />} />
            <Route path="discord" element={<Discord />} />
            <Route path="twitch" element={<Twitch />} />
            <Route path="twitter" element={<Twitter />} />
            <Route path="youtube" element={<YouTube />} />
            <Route path="login" element={<Login />}>
              <Route index element={<TwitchLogin />} />
              <Route path="twitch" element={<TwitchLogin />} />
              <Route path="discord" element={<DiscordLogin />} />
              <Route path="twitter" element={<TwitterLogin />} />
            </Route>
            <Route path="*" element={<NotFound />} />
  
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    );
  }
}

function checkIfLive(live, setLive, vods, setVods) {
  axios.post(`${process.env.REACT_APP_SERVER_URL}/isLive`, { username: "gulimja"}).then((res) => {
      if (res.data === "offline") {
          return setLive(`offline`);
      } else {
          return setLive(`live`);
      }
  })

  if (vods === undefined) {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/getvods`).then(res => {
      setVods(res.data.data);
    })
  }

  setInterval(() => {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/isLive`, { username: "gulimja"}).then((res) => {
          if (res.data === "offline") {
              return setLive(`offline`);
          } else {
              return setLive(`live`);
          }
      })
  }, 300000)
}

function getInfo(sessionToken, setInfo) {
  axios.post("https://www.gulimja.xyz/connect/getInfo", { token: sessionToken }).then(res => {
    let obj = res.data;
    setInfo(obj);
  }).catch((err) => {
    if (err.response.status === 401) {
      window.localStorage.removeItem("token");
    }
  });
}

export default App;
