import { Helmet } from "react-helmet";
import "./css/default.css";
import "./css/twitch.css";

export function Live({ live, vods }) {
    return (
        <>
        <Helmet>
        <meta content="Gulimja" property="og:title" />
        <meta content="The bun bun bar is always open for you <3" property="og:description" />
        <meta content="https://www.gulimja.xyz/" property="og:url" />
        <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
        <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <div className="container-fluid" style={{backgroundColor: "#332d31"}}>
            <img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" alt="Site Logo" className="mainLogo"></img>

            <div className="row justify-content-center">
                <GetVideoPlayer live={live} vods={vods}/>
            </div>

            <div className="row justify-content-center pc" style={{marginTop: "1%"}}>
                <div className="col-3">
                    <div className="textColumns funkyColourBorder textColumnHeaderBox">
                        <h1 className="textColumnHeader">Rules:</h1>
                    </div>
                    <div className="textColumns funkyColourBorder">
                        <p className="textColumnText">The only hard rules I have is to not be a dick and to respect TOS. My stream is 18+ so anything goes unless you're being creepy or someone asks you not to. <br></br><br></br>This being said, general stream rules apply as they would with anyone. Don't dox me, especially if you know me IRL. No slurs or bullying others. Listen to the mods or myself, if we say not to do something, don't do it.</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="textColumns funkyColourBorder textColumnHeaderBox">
                        <h1 className="textColumnHeader">Specs:</h1>
                    </div>
                    <div className="textColumns funkyColourBorder">
                        <p className="textColumnText">PC:<br></br>
                        Mobo: TUF Gaming x570-plus<br></br>
                        CPU: AMD Ryzen 7 5800x3D<br></br>
                        GPU: NVIDIA RTX 3070<br></br>
                        RAM: Corsair Vengeance LPX 32gb 3200mhz (4x8gb)<br></br>
                        Storage: 1.5tb NvME SSD, 4.5tb SATA HDD<br></br>
                        <br></br>
                        Peripherals:<br></br>
                        Keyboard: Razer Blackwidow v2 Pink Edition<br></br>
                        Mouse: Razer Viper Ultimate Pink Edition<br></br>
                        Headset: JBL Quantum 500/Razer Kraken Kitty Edition<br></br>
                        Microphone: Audio-Technica AT2020+ USB<br></br>
                        VR: Oculus Quest<br></br>
                        <br></br>
                        Live2D:<br></br>
                        Software: VTube Studio<br></br>
                        Camera: iPhone 11</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="textColumns funkyColourBorder textColumnHeaderBox">
                        <h1 className="textColumnHeader">About me:</h1>
                    </div>
                    <div className="textColumns funkyColourBorder">
                        <p className="textColumnText">Hai there, I'm Guli! I'm a small genderconfused bunny from the great land of England. I go by she/they for now. I own a small club in Seoul, Korea called the Cotton Tail, and we regularly invite our guests in to come have a drink and relax with us!<br></br>
                        <br></br>
                        I'm very inconsistent with my streaming at the moment due to IRL stuff but I'm trying to stream at least once a week as I love spending time with you all 💖</p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center mobile" style={{marginTop: "1%"}}>
                <div className="col-3">
                    <button className="textColumns funkyColourBorder textColumnHeaderBox" type="button" data-bs-toggle="collapse" data-bs-target="#rules" aria-expanded="false" aria-controls="rules">
                        <h1 className="textColumnHeader">Rules:</h1>
                    </button>
                    <div className="textColumns funkyColourBorder collapse" id="rules">
                        <p className="textColumnText">The only hard rules I have is to not be a dick and to respect TOS. My stream is 18+ so anything goes unless you're being creepy or someone asks you not to. <br></br><br></br>This being said, general stream rules apply as they would with anyone. Don't dox me, especially if you know me IRL. No slurs or bullying others. Listen to the mods or myself, if we say not to do something, don't do it.</p>
                    </div>
                </div>
                <div className="col-3">
                    <button className="textColumns funkyColourBorder textColumnHeaderBox" type="button" data-bs-toggle="collapse" data-bs-target="#specs" aria-expanded="false" aria-controls="specs">
                        <h1 className="textColumnHeader">Specs:</h1>
                    </button>
                    <div className="textColumns funkyColourBorder collapse" id="specs">
                        <p className="textColumnText">PC:<br></br>
                        Mobo: TUF Gaming x570-plus<br></br>
                        CPU: AMD Ryzen 7 5800x3D<br></br>
                        GPU: NVIDIA RTX 3070<br></br>
                        RAM: Corsair Vengeance LPX 32gb 3200mhz (4x8gb)<br></br>
                        Storage: 1.5tb NvME SSD, 4.5tb SATA HDD<br></br>
                        <br></br>
                        Peripherals:<br></br>
                        Keyboard: Razer Blackwidow v2 Pink Edition<br></br>
                        Mouse: Razer Viper Ultimate Pink Edition<br></br>
                        Headset: JBL Quantum 500/Razer Kraken Kitty Edition<br></br>
                        Microphone: Audio-Technica AT2020+ USB<br></br>
                        VR: Oculus Quest<br></br>
                        <br></br>
                        Live2D:<br></br>
                        Software: VTube Studio<br></br>
                        Camera: iPhone 11</p>
                    </div>
                </div>
                <div className="col-3">
                    <button className="textColumns funkyColourBorder textColumnHeaderBox" type="button" data-bs-toggle="collapse" data-bs-target="#about" aria-expanded="false" aria-controls="about">
                        <h1 className="textColumnHeader">About me:</h1>
                    </button>
                    <div className="textColumns funkyColourBorder collapse" id="about">
                        <p className="textColumnText">Hai there, I'm Guli! I'm a small genderconfused bunny from the great land of England. I go by she/they for now. I own a small club in Seoul, Korea called the Cotton Tail, and we regularly invite our guests in to come have a drink and relax with us!<br></br>
                        <br></br>
                        I'm very inconsistent with my streaming at the moment due to IRL stuff but I'm trying to stream at least once a week as I love spending time with you all 💖</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

function GetVideoPlayer({ live, vods }) {
    if (live === "live") {
        return(
            <>
            <iframe title="twitchStream" src="https://player.twitch.tv/?channel=gulimja&parent=www.gulimja.xyz" allowFullScreen={true} className="twitchPlayer colourBorderLeft"></iframe>
            <iframe title="vodViewer" src="https://www.twitch.tv/embed/gulimja/chat?darkpopout&parent=www.gulimja.xyz" className="twitchChat colourBorderRightYellow"></iframe>
            </>
        )
    } else {
        console.log(vods)
        let vodObjs;

        if (vods !== undefined) {
            vodObjs = vods.map(vod => {
                return (
                    <button className="videoButton" onClick={() => { changeVod(vod.id) }}>
                        <div className="vodObject">
                            <img className="thumbnail funkyColourBorder" src={vod.thumbnail} alt={`${vod.title} thumbnail`}></img>
                            <p>{vod.title}</p>
                        </div>
                    </button>
                )
            })
        }

        return(
            <>
            <iframe id="vodPlayer" title="vodPlayer" src={vods ? `https://player.twitch.tv/?video=${vods[0].id}&parent=www.gulimja.xyz` : "https://player.twitch.tv/?parent=www.gulimja.xyz"} allowFullScreen={true} className="twitchPlayer colourBorderLeft"></iframe>
            <div className="vodContainer colourBorderRightYellow">
                {vodObjs}
            </div>
            </>
        )
    }
}

function changeVod(vodID) {
    document.getElementById("vodPlayer").setAttribute("src", `https://player.twitch.tv/?video=${vodID}&parent=www.gulimja.xyz`)
}