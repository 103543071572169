import { Helmet } from "react-helmet";
import "../../css/forms.min.css";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

export function Bot() {
    const captchaRef = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function parseForm(formData) {
        let newObj = [
            {
                "Question" : "What is your name?",
                "Answer" : formData['name']
            },
            {
                "Question": "What is your Discord tag?",
                "Answer": formData['discord']
            },
            {
                "Question": "What is your email?",
                "Answer": formData['email']
            },
            {
                "Question" : "Auto clips?",
                "Answer" : formData['clips']
            },
            {
                "Question" : "Reaction roles?",
                "Answer" : formData['roles']
            },
            {
                "Question" : "Auto twitch?",
                "Answer" : formData['twitch']
            },
            {
                "Question" : "How many customs?",
                "Answer" : formData['custom']
            },
            {
                "Question" : "Description",
                "Answer" : formData['description']
            },
        ]

        return newObj;
    }

    function checkForm(e) {
        const token = captchaRef.current.getValue();
        if (token) {
            $("#recaptchaValid").prop("checked", true);
        }

        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    e.preventDefault();

                    captchaRef.current.reset();
                    $("#recaptchaValid").prop("checked", false);



                    axios.post(`${process.env.REACT_APP_SERVER_URL}/recaptcha`, {token})
                    .then(res =>  {
                        if (res.status === 200) {
                            var formData = {
                                name: $("#name").val(),
                                discord: $("#discord").val(),
                                email: $("#email").val(),
                                roles: $("#roles").is(":checked"),
                                clips: $("#clips").is(":checked"),
                                twitch: $("#twitch").is(":checked"),
                                custom: $("#custom").val(),
                                description: $("#description").val(),
                            };

                            let newObj = parseForm(formData);

                            console.log(newObj)

                            axios.post(`${process.env.REACT_APP_SERVER_URL}/bot`, newObj).then(data => {
                                console.log(data)
                                
                                window.scrollTo(0, 0);
                                document.getElementById("formBox").style.display = "none";
                                document.getElementById("success").style.display = "block";
                                document.getElementById('leadPara').style.display = "none";
                            })
                    
                            // $.ajax({
                            //     type: "POST",
                            //     url: "process.env/mod",
                            //     data: newObj,
                            //     dataType: "json",
                            //     encode: true,
                            // }).done(function (data) {
                            //     console.log(data)
                    
                            //     document.getElementById("formBox").style.display = "none";
                            //     document.getElementById("success").style.display = "block";
                            //     document.getElementById('leadPara').style.display = "none";
                            // })
                        }
                    })
                    .catch((error) => {
                    console.log(error);
                    })
                }
            
                form.classList.add('was-validated')
            });
    }
    
    return (
        <>
        <Helmet>
            <meta content="Gulimja - Website Form" property="og:title" />
            <meta content="Commission a site from Guli <3" property="og:description" />
            <meta content="https://www.gulimja.xyz/commission/website" property="og:url" />
            <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
            <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <p id="leadPara" className="lead" style={{color: "white", textAlign: "center"}}>This is a form to submit a bot commission request to Guli! Bots are priced as follows: £30 base price, £30 for each premade feature adapted for you, and £15 per hour it takes to develop each of your custom features.</p>

        <div className="row formBox" id="formBox">
            <form className="needs-validation" id="form" onSubmit={checkForm} noValidate>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">What is your name?*</label>
                    <input type="text" className="form-control" placeholder="Gulimja" name="name" id="name" aria-label="Name input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="discord" className="form-label">What is your Discord tag?*</label>
                    <input type="text" className="form-control" placeholder="Guli#1453" name="discord" id="discord" aria-label="Discord input" required></input>
                    
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">What is your email address?*</label>
                    <input type='email' className="form-control" name="email" id="email" placeholder="gulimjavt@gmail.com" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="features" className="form-label">Here are the features I already have, please select the ones you'd like. (+£30 each)*</label>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="clips" name="clips"></input>
                        <label className="form-check-label" htmlFor="clips">
                            Auto post twitch clips
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="roles" name="roles"></input>
                        <label className="form-check-label" htmlFor="roles">
                            Reaction roles
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="twitch" name="twitch"></input>
                        <label className="form-check-label" htmlFor="twitch">
                            Twitch live notifications
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="custom" className="form-label">How many custom features would you like?*</label>
                    <input type="number" className="form-control" placeholder="0" id="custom" name="custom" required></input>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Could you please write a message with the name of the bot, the status message you'd like, as well as a description of any additional features. I will follow up with you on these, but these give me an idea of what you're looking for*</label>
                    <textarea className="form-control" id="description" name="description" rows="3" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're happy to submit this data to Guli to review.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're over 18.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => {$("#recaptchaValid").prop("checked", true);}} onExpired={() => {$("#recaptchaValid").prop("checked", false);}}/>
                        <input className="form-control d-none" type="checkbox" id="recaptchaValid" required ></input>
                        <div className="invalid-feedback">
                            Please complete the ReCaptcha.
                        </div>
                        <div className="valid-feedback">
                            Done.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Submit form</button>
                </div>
            </form>
        </div>

        <div className="row formBox justify-content-center" id="success">
            <h1 className="display-1">Success!</h1>
            <p className="lead">Your bot commission has been successfully submitted!</p>
            <p>Your bot commission will be looked over by Guli as soon as possible. Please look on your Discord for a friend request from Guli#1453.</p>

            <NavLink className="homeButton funkyColourBorder" to="/" style={{paddingTop: "1.5%", paddingBottom: "1.5%"}}>Home</NavLink>
        </div>
        </>
    )
}