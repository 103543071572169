import "./css/default.css";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";

export function Home({ vod }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    animateScroll();

    const caroImgStyle = {
        width: "85%",
        marginRight: "auto", 
        marginLeft: "auto",
    }

    return (
        <>
        <Helmet>
        <meta content="Gulimja" property="og:title" />
        <meta content="The bun bun bar is always open for you <3" property="og:description" />
        <meta content="https://www.gulimja.xyz/" property="og:url" />
        <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
        <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <div className="container-fluid" style={{backgroundColor: "#332d31"}}>
            <img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" alt="Main Logo" className="mainLogo"></img>

            <div className="twitch-video">
                <iframe id="vod" title="VOD" className="colourBorder" src={`https://player.twitch.tv/?video=${vod.id}&parent=www.gulimja.xyz`} frameBorder="0" allowFullScreen={true} height="100%" width="100%"></iframe>
            </div>

            <div className="row justify-content-center reveal">
                <div className="col-4 col-4-5 homeLeft" style={{backgroundColor: "#13110f", aspectRatio: "4/5", borderTopLeftRadius: "25px"}} id="../imgBox">
                    <div id="guliCaru" className="carousel slide" data-bs-ride="carousel" style={{paddingTop: "5%", height: "98%", width: "90%", marginRight: "auto", marginLeft: "auto", display: "block"}}>
                        <div className="carousel-indicators">
                        <button type="button" data-bs-target="#guliCaru" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#guliCaru" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#guliCaru" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner carouselCenter">
                        <div className="carousel-item active">
                            <img src="https://i.ibb.co/r0nwmR5/Commission.png" alt="Commission" className="d-block" style={caroImgStyle}></img>
                        </div> 
                        <div className="carousel-item">
                            <img src="https://i.ibb.co/7rYjHQ8/Model.png" alt="Model" className="d-block" style={caroImgStyle}></img>
                        </div>
                        <div className="carousel-item">
                            <img src="https://i.ibb.co/s9BNhJ4/Cheerleader.png" alt="Cheerleader" className="d-block" style={caroImgStyle}></img>
                        </div>
                        </div>
                        <button className="carousel-control-prev carouselCenter" type="button" data-bs-target="#guliCaru" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next carouselCenter" type="button" data-bs-target="#guliCaru" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className="col-4 col-4-5 homeRight pc" style={{backgroundColor: "#13110f", aspectRatio: "4/5"}} id="textBox">
                    <figure className="text-center" style={{width: "90%", marginLeft: "auto", marginRight: "auto", display: "border"}}  id='contentRow'>
                        <h1 className="display-1 aboutMeHeader" style={{marginTop: "5%", color: "white"}}>About Me:</h1>

                        <p className="aboutMeText" style={{color: "white",}}>Heyya! Welcome to the Cotton Tail my lil bunbun~! I'm Gulimja, Guli for short, and I'm the owner of this here establishment. We run a tight ship here to ensure you have the best time possible~ I hope you enjoy your time hehe~!<br></br><br></br>

You must be wondering who I am and why you're listening to me ramble, so I'll cut to the chase. As I said I'm Guli! A lil bunny girl Vtuber from England here to make your day just a little bit brighter! You cans see some pictures of me over to the left here, in all the different outfits I may serve you in. By day we run an orderly establishment, a nice little bar in the heart of Seoul, but at night things turn a little more.. well devious as you can see. During the day you'll find me mostly playing games, either competitive or just some fun story or challenge games. I may get a little bit.. feisty but it's all part of the fun~<br></br><br></br>

I have some lore behind me, and you can find out more by exploring the lovely page that you're on! There's lots to see so don't be shy~. You can find all the links to my social media pages below, and if you look on the navigation bar you can see some of my VODs, or watch my stream live if I'm on! <br></br><br></br>

I hope you enjoy your stay at the Cotton Tail! See you soon~

                    </p>

                    </figure>
                </div>
                <div className="col-3 mobile">
                    <button className="textColumns funkyColourBorder textColumnHeaderBox" type="button" data-bs-toggle="collapse" data-bs-target="#aboutMe" aria-expanded="false" aria-controls="aboutme">
                        <h1 className="textColumnHeader">About me:</h1>
                    </button>
                </div>
                <div className="col-3 mobile">
                    <div className="textColumns funkyColourBorder collapse" id="aboutMe">
                        <p className="textColumnText">Heyya! Welcome to the Cotton Tail my lil bunbun~! I'm Gulimja, Guli for short, and I'm the owner of this here establishment. We run a tight ship here to ensure you have the best time possible~ I hope you enjoy your time hehe~!<br></br><br></br>

You must be wondering who I am and why you're listening to me ramble, so I'll cut to the chase. As I said I'm Guli! A lil bunny girl Vtuber from England here to make your day just a little bit brighter! You cans see some pictures of me over to the left here, in all the different outfits I may serve you in. By day we run an orderly establishment, a nice little bar in the heart of Seoul, but at night things turn a little more.. well devious as you can see. During the day you'll find me mostly playing games, either competitive or just some fun story or challenge games. I may get a little bit.. feisty but it's all part of the fun~<br></br><br></br>

I have some lore behind me, and you can find out more by exploring the lovely page that you're on! There's lots to see so don't be shy~. You can find all the links to my social media pages below, and if you look on the navigation bar you can see some of my VODs, or watch my stream live if I'm on! <br></br><br></br>

I hope you enjoy your stay at the Cotton Tail! See you soon~</p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center reveal">
                <div className="col-9" style={{paddingLeft: "0%", paddingRight: "0%"}}>
                    <NavLink className="socialButton colourBorder" to="https://www.twitch.tv/gulimja" style={{float: "left"}}>Twitch</NavLink>
                    <NavLink className="socialButton colourBorderYellow" to="https://www.twitter.com/gulimjavt" style={{float: "right"}}>Twitter</NavLink>
                </div>
            </div>
            <div className="row justify-content-center reveal">
                <div className="col-9" style={{paddingLeft: "0%", paddingRight: "0%"}}>
                    <NavLink className="socialButton colourBorderYellow" to="https://www.youtube.com/@gulimjavt" style={{float: "left"}}>YouTube</NavLink>
                    <NavLink className="socialButton colourBorder" type="button" to="https://www.redbubble.com/people/GulimjaVT/shop" style={{float: "right"}}>Redbubble</NavLink>
                </div>
            </div>

            <div className="row justify-content-center reveal">
                <div className="col-9" style={{paddingLeft: "0%", paddingRight: "0%"}}>
                    <NavLink className="socialButton funkyColourBorder" to="https://www.throne.me/gulimja" style={{float: "left", width: "100%"}} id="largeBooton">Throne Gifts</NavLink>
                </div>
            </div>

            <div className="row justify-content-center reveal" style={{marginTop: "1%"}}>
                <div className="col-4">
                    <div className="card">
                        <div className="smallSection">
                            <h1 className="text-center cardHeader">Team</h1>
                            <img className="cardEmotes" src="https://cdn.discordapp.com/attachments/474300607948390411/1075027683869737052/guliheart.png" alt="GuliHeart emoji"></img>
                        </div>
                        <div className="largeSection">
                            <p className="text-center cardText">Come check out the awesome people who make my channel possible! Meet my art parents, as well as the mods ❤️</p>
                            <row className="justify-content-center cardButtons">
                                <div className="col-12">
                                    <NavLink className="cardButton twoCardButton" to="/team/moderators" style={{float: "left", marginLeft: "6.5%"}}>Mods</NavLink>
                                    <NavLink className="cardButton twoCardButton" to="/team/artists" style={{float: "right", marginRight: "6.5%"}}>Artists</NavLink>
                                </div>
                            </row>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="smallSection">
                            <h1 className="text-center cardHeader">Applications</h1>
                            <img className="cardEmotes" src="https://cdn.discordapp.com/attachments/474300607948390411/1075027684133974046/pats.png" alt="GuliHeatpat emoji"></img>
                        </div>
                        <div className="largeSection">
                            <p className="text-center cardText">Here you can apply to collab with me or become part of my awesome team! Applications are always open so you can apply whenever 💖</p>
                            <row className="justify-content-center cardButtons">
                                <div className="col-12">
                                    <NavLink className="cardButton threeCardButton" to="/applications/moderator" style={{float: "left", marginLeft: "5%"}}>Moderators</NavLink>
                                    <NavLink className="cardButton threeCardButton" to="/applications/media" style={{float: "left", marginRight: "4%", marginLeft: "4.5%"}}>Media Team</NavLink>
                                    <NavLink className="cardButton threeCardButton" to="/applications/collab" style={{float: "right", marginRight: "5%"}}>Collabs</NavLink>
                                </div>
                            </row>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="smallSection">
                            <h1 className="text-center cardHeader">Merch</h1>
                            <img className="cardEmotes" src="https://cdn.discordapp.com/attachments/474300607948390411/1075027684469522452/horni.png" alt="GuliHorni emote"></img>
                        </div>
                        <div className="largeSection">
                            <p className="text-center cardText">Here are the links to all my merch! I don't have much but if you're interested send me a picture of what you get!</p>
                            <row className="justify-content-center cardButtons">
                                <div className="col-12">
                                    <NavLink className="cardButton threeCardButton" to="https://www.redbubble.com/shop/ap/157528627" style={{float: "left", marginLeft: "5%"}}>Original Guli</NavLink>
                                    <NavLink className="cardButton threeCardButton" to="https://www.redbubble.com/shop/ap/158201313" style={{float: "left", marginRight: "4%", marginLeft: "4.5%"}}>Cyber Guli</NavLink>
                                    <NavLink className="cardButton threeCardButton" to="https://www.redbubble.com/shop/ap/158201373" style={{float: "right", marginRight: "5%"}}>Logo</NavLink>
                                </div>
                            </row>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

function animateScroll() {
    document.onscroll = (e) => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show")
                }
            })
        });
    
        let hiddenElements = document.querySelectorAll(".reveal");
        hiddenElements.forEach((element) => {
            observer.observe(element)
        })
    }
}