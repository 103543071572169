import { useEffect } from "react";

export function Discord() {
    useEffect(() => {
        window.location.replace('https://www.discord.com/invite/2pS5MtKjCH')
    })
}

export function Twitch() {
    useEffect(() => {
        window.location.replace('https://www.twitch.tv/gulimja')
    })
}

export function Twitter() {
    useEffect(() => {
        window.location.replace('https://twitter.com/GulimjaVT')
    })
}

export function YouTube() {
    useEffect(() => {
        window.location.replace('https://www.youtube.com/channel/UCtB7JM4iDTkw-O9xf94ElEg')
    })
}