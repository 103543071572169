import { Helmet } from "react-helmet";
import "../../css/forms.min.css";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "./website.css";

export function Website() {
    const captchaRef = useRef(null)

    useEffect(() => {
        document.getElementById("mod").classList.remove("active")
    }, [])

    function parseForm(formData) {
        let newObj = [
            {
                "Question" : "What is your name?",
                "Answer" : formData['name']
            },
            {
                "Question": "What is your Discord tag?",
                "Answer": formData['discord']
            },
            {
                "Question": "What is your email?",
                "Answer": formData['email']
            },
            {
                "Question" : "How many pages?",
                "Answer" : formData['pages']
            },
            {
                "Question" : "Description",
                "Answer" : formData['description']
            },
        ]

        return newObj;
    }

    function checkForm(e) {
        const token = captchaRef.current.getValue();
        if (token) {
            $("#recaptchaValid").prop("checked", true);
        }

        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
                let fields;
                if (vw > 770) {
                    fields = document.getElementsByClassName("mobile");
                } else {
                    fields = document.getElementsByClassName("pc");
                }

                for (let i = 0; i < fields.length; i++) {
                    fields[i].setAttribute("required", false);
                }


                if (!form.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    e.preventDefault();

                    captchaRef.current.reset();
                    $("#recaptchaValid").prop("checked", false);



                    axios.post(`${process.env.REACT_APP_SERVER_URL}/recaptcha`, {token})
                    .then(res =>  {
                        if (res.status === 200) {
                            let formData;

                            if (vw > 770) {
                                formData = {
                                    name: $("#name").val(),
                                    discord: $("#discord").val(),
                                    email: $("#email").val(),
                                    pages: $("#howMany").val(),
                                    description: $("#description").val(),
                                };
                            } else {
                                formData = {
                                    name: $("#nameMobile").val(),
                                    discord: $("#discordMobile").val(),
                                    email: $("#emailMobile").val(),
                                    pages: $("#howManyMobile").val(),
                                    description: $("#descriptionMobile").val(),
                                };
                            }

                            let newObj = parseForm(formData);

                            console.log(newObj)

                            axios.post(`${process.env.REACT_APP_SERVER_URL}/website`, newObj).then(data => {
                                console.log(data)
                                
                                window.scrollTo(0, 0);
                                document.getElementsByClassName("pcMain")[0].style.display = "none";
                                document.getElementsByClassName("mobileMain")[0].style.display = "none";
                                document.getElementById("success").style.display = "block";
                                document.getElementById('leadPara').style.display = "none";
                            })
                    
                            // $.ajax({
                            //     type: "POST",
                            //     url: "process.env/mod",
                            //     data: newObj,
                            //     dataType: "json",
                            //     encode: true,
                            // }).done(function (data) {
                            //     console.log(data)
                    
                            //     document.getElementById("formBox").style.display = "none";
                            //     document.getElementById("success").style.display = "block";
                            //     document.getElementById('leadPara').style.display = "none";
                            // })
                        }
                    })
                    .catch((error) => {
                    console.log(error);
                    })
                }
            
                form.classList.add('was-validated')
            });
    }
    
    return (
        <>
        <Helmet>
            <meta content="Gulimja - Website Form" property="og:title" />
            <meta content="Commission a site from Guli <3" property="og:description" />
            <meta content="https://www.gulimja.xyz/commission/website" property="og:url" />
            <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
            <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <p id="leadPara" className="lead" style={{color: "white", textAlign: "center"}}>This is a form to submit a website commission request to Guli!</p>

        <div className="row formBox pcMain" id="formBox">
            <form className="needs-validation" id="form" onSubmit={checkForm} noValidate>
                <div className="row pc">
                    <div className="col-8">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">What is your name?*</label>
                            <input type="text" className="form-control pc" placeholder="Gulimja" name="name" id="name" aria-label="Name input" required></input>
                            {/* <div className="valid-feedback">
                                Looking good!
                            </div>
                            <div className="invalid-feedback">
                                This field is required.
                            </div> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="discord" className="form-label">What is your Discord tag?</label>
                            <input type="text" className="form-control pc" placeholder="Guli#1453" name="discord" id="discord" aria-label="Discord input"></input>
                            
                            {/* <div className="valid-feedback">
                                Looking good!
                            </div>
                            <div className="invalid-feedback">
                                This field is required.
                            </div> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">What is your email address?*</label>
                            <input type='email' className="form-control pc" name="email" id="email" placeholder="gulimjavt@gmail.com" required></input>
                            {/* <div className="valid-feedback">
                                Looking good!
                            </div>
                            <div className="invalid-feedback">
                                This field is required.
                            </div> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="howMany" className="form-label">How many pages would you like? (An estimate is fine if you don't know 100%)*</label>
                            <input type="number" className="form-control pc" placeholder="1" min="1" id="howMany" name="howMany" required></input>
                            {/* <div className="valid-feedback">
                                Looking good!
                            </div>
                            <div className="invalid-feedback">
                                This field is required.
                            </div> */}
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="funkyColourBorder" id="pricesBox">
                            <h1>Pricing:</h1>
                            <p id="pricingInfo" className="lead">Basic pages: £15/hr<br></br>Animated/advanced pages: £20/hr<br></br><br></br>Features to add to pages:<br></br>Forms: +£20 per form<br></br>Photo gallery: +£10 per gallery<br></br>Live twitch viewer (with chat): +£10<br></br>VOD viewer: +£30<br></br>Other: Price on request</p>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Can you please describe what you're looking for on your site, as simple or in depth as you'd like.*</label>
                    <textarea className="form-control pc" id="description" name="description" rows="3" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input pc" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're happy to submit this data to Guli to review.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input pc" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're over 18.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => {$("#recaptchaValid").prop("checked", true);}} onExpired={() => {$("#recaptchaValid").prop("checked", false);}}/>
                        <input className="form-control d-none" type="checkbox" id="recaptchaValid" required ></input>
                        <div className="invalid-feedback">
                            Please complete the ReCaptcha.
                        </div>
                        <div className="valid-feedback">
                            Done.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Submit form</button>
                </div>
            </form>
        </div>

        <div className="row formBox mobileMain" id="formBox">
            <form className="needs-validation" id="form" onSubmit={checkForm} noValidate>
                <div>
                    <div className="funkyColourBorder" id="pricesBox">
                        <h1>Pricing:</h1>
                        <p id="pricingInfo" className="lead">Per basic page: £30<br></br>Per page with animations: £50<br></br><br></br>Features to add to pages:<br></br>Forms: +£20 per form<br></br>Photo gallery: +£10 per gallery<br></br>Live twitch viewer (with chat): +£5<br></br>VOD viewer: +£30<br></br>Other: Price on request</p>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="nameMobile" className="form-label">What is your name?*</label>
                        <input type="text" className="form-control mobile" placeholder="Gulimja" name="nameMobile" id="nameMobile" aria-label="Name input" required></input>
                        {/* <div className="valid-feedback">
                            Looking good!
                        </div>
                        <div className="invalid-feedback">
                            This field is required.
                        </div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="discordMobile" className="form-label">What is your Discord tag?</label>
                        <input type="text" className="form-control" placeholder="Guli#1453" name="discordMobile" id="discordMobile" aria-label="Discord input"></input>
                        
                        {/* <div className="valid-feedback">
                            Looking good!
                        </div>
                        <div className="invalid-feedback">
                            This field is required.
                        </div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="emailMobile" className="form-label">What is your email address?*</label>
                        <input type='email' className="form-control mobile" name="emailMobile" id="emailMobile" placeholder="gulimjavt@gmail.com" required></input>
                        {/* <div className="valid-feedback">
                            Looking good!
                        </div>
                        <div className="invalid-feedback">
                            This field is required.
                        </div> */}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="howManyMobile" className="form-label">How many pages would you like? (An estimate is fine if you don't know 100%)*</label>
                        <input type="number" className="form-control mobile" placeholder="1" min="1" id="howManyMobile" name="howManyMobile" required></input>
                        {/* <div className="valid-feedback">
                            Looking good!
                        </div>
                        <div className="invalid-feedback">
                            This field is required.
                        </div> */}
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="descriptionMobile" className="form-label">Can you please describe what you're looking for on your site, as simple or in depth as you'd like.*</label>
                    <textarea className="form-control mobile" id="descriptionMobile" name="descriptionMobile" rows="3" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input mobile" type="checkbox" value="" id="termsMobile" required></input>
                        <label className="form-check-label" htmlFor="termsMobile">Please check to confirm that you're happy to submit this data to Guli to review.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input mobile" type="checkbox" value="" id="termsMobile" required></input>
                        <label className="form-check-label" htmlFor="termsMobile">Please check to confirm that you're over 18.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => {$("#recaptchaValidMobile").prop("checked", true);}} onExpired={() => {$("#recaptchaValid").prop("checked", false);}}/>
                        <input className="form-control d-none mobile" type="checkbox" id="recaptchaValidMobile" required ></input>
                        <div className="invalid-feedback">
                            Please complete the ReCaptcha.
                        </div>
                        <div className="valid-feedback">
                            Done.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Submit form</button>
                </div>
            </form>
        </div>

        <div className="row formBox justify-content-center" id="success">
            <h1 className="display-1">Success!</h1>
            <p className="lead">Your website commission has been successfully submitted!</p>
            <p>Your website commission will be looked over by Guli as soon as possible. Please look on your Discord for a friend request from Guli#1453.</p>

            <NavLink className="homeButton funkyColourBorder" to="/" style={{paddingTop: "1.5%", paddingBottom: "1.5%"}}>Home</NavLink>
        </div>
        </>
    )
}