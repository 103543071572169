import { Helmet } from "react-helmet";
import "../../css/forms.min.css";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

export function Moderator() {
    const captchaRef = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function parseForm(formData) {
        let newObj = [
            {
                "Question" : "What is your name?",
                "Answer" : formData['name']
            },
            {
                "Question": "What is your Discord tag?",
                "Answer": formData['discord']
            },
            {
                "Question": "What is your twitch?",
                "Answer": formData['twitch']
            },
            {
                "Question": "What is your email?",
                "Answer": formData['email']
            },
            {
                "Question": "Can you do monday?",
                "Answer": formData['monday']
            },
            {
                "Question": "Can you do tuesday?",
                "Answer": formData['tuesday']
            },
            {
                "Question": "Can you do wednesday?",
                "Answer": formData['wednesday']
            },
            {
                "Question": "Can you do thursday?",
                "Answer": formData['thursday']
            },
            {
                "Question": "Can you do friday?",
                "Answer": formData['friday']
            },
            {
                "Question": "Can you do saturday?",
                "Answer": formData['saturday']
            },
            {
                "Question": "Can you do sunday?",
                "Answer": formData['sunday']
            },
            {
                "Question": "Why do you want to moderate for me?",
                "Answer": formData['why']
            },
            {
                "Question": "What other experience do you have?",
                "Answer": formData['experience']
            },
            {
                "Question": "Anything else?",
                "Answer": formData['other']
            }
        ]

        return newObj;
    }

    function checkForm(e) {
        const token = captchaRef.current.getValue();
        if (token) {
            $("#recaptchaValid").prop("checked", true);
        }

        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    e.preventDefault();

                    captchaRef.current.reset();
                    $("#recaptchaValid").prop("checked", false);



                    axios.post(`${process.env.REACT_APP_SERVER_URL}/recaptcha`, {token})
                    .then(res =>  {
                        if (res.status === 200) {
                            var formData = {
                                name: $("#name").val(),
                                discord: $("#discord").val(),
                                twitch: $("#twitch").val(),
                                email: $("#email").val(),
                                why: $("#why").val(),
                                monday: $("#monday").is(":checked"),
                                tuesday: $("#tuesday").is(":checked"),
                                wednesday: $("#wednesday").is(":checked"),
                                thursday: $("#thursday").is(":checked"),
                                friday: $("#friday").is(":checked"),
                                saturday: $("#saturday").is(":checked"),
                                sunday: $("#sunday").is(":checked"),
                                experience: $("#days").val(),
                                other: $("#other").val(),
                            };

                            let newObj = parseForm(formData);

                            console.log(newObj)

                            axios.post(`${process.env.REACT_APP_SERVER_URL}/mod`, newObj).then(data => {
                                console.log(data)
                                
                                window.scrollTo(0, 0);
                                document.getElementById("formBox").style.display = "none";
                                document.getElementById("success").style.display = "block";
                                document.getElementById('leadPara').style.display = "none";
                            })
                    
                            // $.ajax({
                            //     type: "POST",
                            //     url: "process.env/mod",
                            //     data: newObj,
                            //     dataType: "json",
                            //     encode: true,
                            // }).done(function (data) {
                            //     console.log(data)
                    
                            //     document.getElementById("formBox").style.display = "none";
                            //     document.getElementById("success").style.display = "block";
                            //     document.getElementById('leadPara').style.display = "none";
                            // })
                        }
                    })
                    .catch((error) => {
                    console.log(error);
                    })
                }
            
                form.classList.add('was-validated')
            });
    }
    
    return (
        <>
        <Helmet>
            <meta content="Gulimja - Moderation Form" property="og:title" />
            <meta content="Come join Guli's team <3" property="og:description" />
            <meta content="https://www.gulimja.xyz/moderator" property="og:url" />
            <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
            <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <p id="leadPara" className="lead" style={{color: "white", textAlign: "center"}}>This is the application to be a moderator on my Twitch and Discord channels! You will be required to be active on both platforms.</p>

        <div className="row formBox" id="formBox">
            <form className="needs-validation" id="form" onSubmit={checkForm} noValidate>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">What is your name?*</label>
                    <input type="text" className="form-control" placeholder="Gulimja" name="name" id="name" aria-label="Name input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="discord" className="form-label">What is your Discord tag?*</label>
                    <input type="text" className="form-control" placeholder="Guli#0001" name="discord" id="discord" aria-label="Discord input" required></input>
                    
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="twitch" className="form-label">What is your twitch name?*</label>
                    <input type='text' className="form-control" name="twitch" id="twitch" placeholder="gulimja" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">What is your email?*</label>
                    <input type="email" className="form-control" name="email" placeholder="gulimjavt@gmail.com" id="email" aria-label="Email input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="why" className="form-label">Why do you want to be a moderator?*</label>
                    <textarea className="form-control" id="why" name="why" rows="4" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="checks" className="form-label">I normally stream at 9pm BST 2-3 times a week. Which days would you be able to moderate on?*</label>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="monday" name="monday"></input>
                        <label className="form-check-label" htmlFor="monday">
                            Monday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="tuesday" name="tuesday"></input>
                        <label className="form-check-label" htmlFor="tuesday">
                            Tuesday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="wednesday" name="wednesday"></input>
                        <label className="form-check-label" htmlFor="wednesday">
                            Wednesday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="thursday" name="thursday"></input>
                        <label className="form-check-label" htmlFor="thursday">
                            Thursday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="friday" name="friday"></input>
                        <label className="form-check-label" htmlFor="friday">
                            Friday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="saturday" name="saturday"></input>
                        <label className="form-check-label" htmlFor="saturday">
                            Saturday
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="sunday" name="sunday"></input>
                        <label className="form-check-label" htmlFor="sunday">
                            Sunday
                        </label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="days" className="form-label">Have you had any other experience moderating for content creators? If yes, please elaborate on your answer*</label>
                    <textarea className="form-control" id="days" name="days" rows="3" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="other" className="form-label">Anything you would like to add to aid your application?</label>
                    <textarea className="form-control" id="other" name="other" rows="4"></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="personal" required></input>
                        <label className="form-check-label" htmlFor="personal">By becoming a moderator, you agree to not try to abuse your position to get closer to Guli on a personal level. Please tick to confirm you understand this.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're happy to submit this data to Guli and their moderation team htmlFor review.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're over 18.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => {$("#recaptchaValid").prop("checked", true);}} onExpired={() => {$("#recaptchaValid").prop("checked", false);}}/>
                        <input className="form-control d-none" type="checkbox" id="recaptchaValid" required ></input>
                        <div className="invalid-feedback">
                            Please complete the ReCaptcha.
                        </div>
                        <div className="valid-feedback">
                            Done.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Submit form</button>
                </div>
            </form>
        </div>

        <div className="row formBox justify-content-center" id="success">
            <h1 className="display-1">Success!</h1>
            <p className="lead">Your moderation application has been successfully submitted!</p>
            <p>Our team will now review your application. We aim to get back to you shortly however we cannot guarentee a timeframe. Please look on your Discord for a friend request from Guli#0001.</p>

            <NavLink className="homeButton funkyColourBorder" to="/" style={{paddingTop: "1.5%", paddingBottom: "1.5%"}}>Home</NavLink>
        </div>
        </>
    )
}

