import { NavLink } from "react-router-dom"

export function NotFound() {
    return(
        <>
        <div className="container" style={{minHeight: "58.3vh"}}>
            <img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" alt="Main Logo" className="mainLogo"></img>

            <div className="row nf404 justify-content-center">
                <h1 className="display-1">404 - Not Found</h1>
                <p className="lead">We're sorry!</p>
                <p>We couldn't find the content that you were looking for. Please return to the homepage by pressing the button below.</p>

                <NavLink className="homeButton funkyColourBorder" to="/">Home</NavLink>
            </div>
        </div>
        </>
    )
}