import React from "react";
import { NavLink } from "react-router-dom";
import "./css/team.css";
import { useEffect } from "react";

export function ArtistTeam() {
    useEffect(() => {
        document.getElementById("mod").classList.remove("active")
    }, [])

    return (
        <>
        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeft leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Blob - Artist</h1>
                <p className="infoText">I'm the goblin artist that also has an unhealthy obsession with pocky.</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://instagram.com/0_blob_0" style={{float: "left", marginLeft: "6.5%"}}>Instagram</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://ko-fi.com/blob0008" style={{float: "right", marginRight: "6.5%"}}>Ko-fi</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRightYellow rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/DwkzxjX/Blob.png" alt="Blob"></img>
            </div>
        </div>

        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeftYellow leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Daffy - Misc</h1>
                <p className="infoText">Your local, friendly duck. I do arts and animation, that's it 💛🦆</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.instagram.com/honey_daffy/" style={{float: "left", marginLeft: "6.5%"}}>Instagram</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/daffyvt" style={{float: "right", marginRight: "6.5%"}}>Twitch</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRight rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/t8g7tXS/Daffy.png" alt="Daffy"></img>
            </div>
        </div>

        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeft leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Delta - Rigger</h1>
                <p className="infoText">I'm an Artist, Animator and Rigger, and I've known Guli a while now! Check my socials for updates on my work!</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Strykerr03" style={{float: "left", marginLeft: "6.5%"}}>Twitter</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ" style={{float: "right", marginRight: "6.5%"}}>YouTube</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRightYellow rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/xzHLvLp/Delta.png" alt="Delta"></img>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Blob - Artist</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/DwkzxjX/Blob.png" alt="Blob"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">I'm the goblin artist that also has an unhealthy obsession with pocky.</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://instagram.com/0_blob_0" >Instagram</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://ko-fi.com/blob0008">Ko-fi</NavLink>
                </div>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Daffy - Misc</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/t8g7tXS/Daffy.png" alt="Daffy"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">Your local, friendly duck. I do arts and animation, that's it 💛🦆</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.instagram.com/honey_daffy/">Instagram</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/daffyvt">Twitch</NavLink>
                </div>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Delta - Rigger</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/xzHLvLp/Delta.png" alt="Delta"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">I'm an Artist, Animator and Rigger, and I've known Guli a while now! Check my socials for updates on my work!</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Strykerr03" >Twitter</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ">YouTube</NavLink>
                </div>
            </div>
        </div>
        </>
    )
}