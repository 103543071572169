export function Footer() {
    return (
        <>
        <div className="row no-gutters" id="footerDiv" style={{borderTop: "3px solid #f5bfcd"}}>
        <footer className="w-100 py-4 flex-shrink-0">
            <div className="container py-4">
                <div className="row gy-4 gx-5">
                    <div className="col-lg-4 col-md-6">
                        <h5 className="h1 text-white">Gulimja</h5>
                        <p className="small text-muted">Developer and indie VTuber working from the UK.</p>
                        <p className="small text-muted mb-0">&copy;2023 Gulimja. All rights reserved. <a className="text-primary" href="https://www.gulimja.xyz/">Gulimja.xyz</a></p>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h5 className="text-white mb-3">Social Media</h5>
                        <ul className="list-unstyled text-muted">
                            <li><a target="_blank" href="https://www.gulimja.xyz/twitch" rel="noreferrer">Twitch</a></li>
                            <li><a target="_blank" href="https://www.gulimja.xyz/twitter" rel="noreferrer">Twitter</a></li>
                            <li><a target="_blank" href="https://www.gulimja.xyz/youtube" rel="noreferrer">Youtube</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h5 className="text-white mb-3">Applications</h5>
                        <ul className="list-unstyled text-muted">
                            <li><a href="https://www.gulimja.xyz/applications/moderator">Moderator</a></li>
                            <li><a href="https://www.gulimja.xyz/applications/media">Media Team</a></li>
                            <li><a href="https://www.gulimja.xyz/applications/collab">Collaboration</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h5 className="text-white mb-3">Join our Discord</h5>
                        <a href="https://www.discord.com/invite/2pS5MtKjCH"><img src="https://discordapp.com/api/guilds/784867504438771713/widget.png?style=banner3" alt="Join the discord"></img></a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    </>
    )
}