import axios from "axios";

export function TwitterLogin() {
    let posted = localStorage.getItem("posted");
    if (!posted) {
        localStorage.setItem("posted", "yes")
        let query = new URLSearchParams(window.location.search);
        console.log((localStorage.getItem("state") !== query.get("state")) || query.get("error"))
        console.log((localStorage.getItem("state") !== query.get("state")))
        console.log(query.get("error"))
        if ((localStorage.getItem("state") !== query.get("state")) || query.get("error")) {
            console.log("error");
            // localStorage.removeItem("state");
            window.location.replace("http://localhost:3000/dashboard");
        } else {
            // localStorage.removeItem("state");
        }
        
        

        let data = {
            code: query.get("code"),
            token: localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token"),
            codeVer: localStorage.getItem("codeVer")
        }

        axios.post(`https://www.gulimja.xyz/connect/twitterLogin`, data).then(res => {
            if (res.status !== 200) console.log("ERROR");

            console.log(res.status);

            localStorage.removeItem("posted")

            window.location.replace("http://localhost:3000/dashboard");
        })
        return (<></>);
    }
}