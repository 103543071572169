import { NavLink } from "react-router-dom";

export function Navbar({ live, info }) {
    return (
        <>
        <div className="row no-gutters" style={{borderBottom: "3px solid #f5bfcd"}}>
            <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <NavLink className="navbar-brand" to="/" exact="true"><img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" height="60rem" alt="Logo"></img></NavLink>                  
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav">
                            <NavLink id="home" className="nav-item nav-link" to="/" exact="true">Home</NavLink>
                            <div className="nav-item dropdown">
                                <NavLink className="nav-item nav-link" to="/applications">Applications</NavLink>
                            </div>
                            <NavLink className="nav-item nav-link" to="/team">Our Team</NavLink>
                            <LiveButton live={live} />
                            <NavLink className="nav-item nav-link" to="/commission">Commissions</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        </>
    )
}

function LiveButton({live}) {
    if (live === "live") {
        return <NavLink className="nav-item nav-link" to="/live"><img alt="LiveButton" src="https://cdn.discordapp.com/attachments/474300607948390411/1074470319542243478/pngwing.png" height="25rem"></img></NavLink>;
    } else {
        return <NavLink className="nav-item nav-link" to="/live">VODs</NavLink>;
    }
}

function redirectLogin(e) {
    e.preventDefault();
    let social = e.target.value;
    let state = makeid(20);
    window.localStorage.setItem("state", state);

    let remember = document.getElementById("rememberMe").checked;
    
    if (remember === true) {
        localStorage.setItem("remember", "yes");
    } else {
        localStorage.setItem("remember", "no");
    }

    if (social === "discord") {
        window.location.replace(`https://discord.com/api/oauth2/authorize?client_id=978233137630953492&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin%2Fdiscord&response_type=code&scope=identify%20guilds&state=${state}`);
    } else {
        window.localStorage.setItem("type", "user");
        window.location.replace(`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=6lj5pdkvworgdxc3ep0i3eva5opzuy&redirect_uri=http://localhost:3000/login/twitch&scope=clips%3Aedit+chat%3Aread+chat%3Aedit&state=${state}`);
    }
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}