import React from "react";
import { NavLink } from "react-router-dom";
import "./css/team.css";

export function ModeratorTeam() {
    return (
        <>
        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeft leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Pr0fessorJJ</h1>
                <p className="infoText">Just your friendly neighborhood chemistry Professor. I chew ass and kick bubblegum, and I'm all out of ass :/.</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Pr0fessorJJ" style={{float: "left", marginLeft: "6.5%"}}>Twitter</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/pr0fessorjj" style={{float: "right", marginRight: "6.5%"}}>Twitch</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRightYellow rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/wNtf5dV/JJ.png" alt="JJ's beautiful face"></img>
            </div>
        </div>

        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeftYellow leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Traviiii</h1>
                <p className="infoText">Intelligent, amazing, competent, funny and beautiful. All words that you would never use to describe me.</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/ItsTraviiii" style={{float: "left", marginLeft: "6.5%"}}>Twitter</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.youtube.com/channel/UCgpwrWWOEbyWEOrzSb_4Z0A" style={{float: "right", marginRight: "6.5%"}}>YouTube</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRight rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/MnjgvYd/Trav.png" alt="Traviii's beautiful face"></img>
            </div>
        </div>

        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeft leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Stryker</h1>
                <p className="infoText">Resident anime connoisseur, also likes the beeps and boops.</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Strykerr03" style={{float: "left", marginLeft: "6.5%"}}>Twitter</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ" style={{float: "right", marginRight: "6.5%"}}>YouTube</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRightYellow rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/XsYpg4j/Stryker.png" alt="Stryker's beautiful face"></img>
            </div>
        </div>

        <div className="row justify-content-center background pc">
            <div className="col-6 colourBorderLeft leftTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Wilco</h1>
                <p className="infoText">I'm the most absent mod here, I'm your last resort, Good luck! :D</p>
                <row className="justify-content-center cardButtons teamCardButtons">
                    <div className="col-12">
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/wilco903" style={{float: "left", marginLeft: "6.5%"}}>Twitch</NavLink>
                        <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ" style={{float: "right", marginRight: "6.5%"}}>YouTube</NavLink>
                    </div>
                </row>
            </div>
            <div className="col-6 colourBorderRightYellow rightTeamColumn" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/Zc8s466/Wilco.png" alt="Wilco"></img>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Pr0fessorJJ</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/wNtf5dV/JJ.png" alt="JJ's beautiful face"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">Just your friendly neighborhood chemistry Professor. I chew ass and kick bubblegum, and I'm all out of ass :/.</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Pr0fessorJJ" >Twitter</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/pr0fessorjj">Twitch</NavLink>
                </div>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Traviiii</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/MnjgvYd/Trav.png" alt="Traviii's beautiful face"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">Intelligent, amazing, competent, funny and beautiful. All words that you would never use to describe me.</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/ItsTraviiii">Twitter</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.youtube.com/channel/UCgpwrWWOEbyWEOrzSb_4Z0A">YouTube</NavLink>
                </div>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Stryker</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/XsYpg4j/Stryker.png" alt="Stryker's beautiful face"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">Resident anime connoisseur, also likes the beeps and boops.</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://twitter.com/Strykerr03" >Twitter</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ">YouTube</NavLink>
                </div>
            </div>
        </div>

        <div className="row justify-content-center background colourBorder mobile">
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <h1 className="nameHeader">Wilco</h1>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <img className="peoplesImages funkyColourBorder" src="https://i.ibb.co/Zc8s466/Wilco.png" alt="Wilco"></img>
            </div>
            <div className="col-12" style={{paddingLeft: "0%", paddingRight: "0%", float: "left"}}>
                <p className="infoText">I'm the most absent mod here, I'm your last resort, Good luck! :D</p>
            </div>
            <div className="row justify-content-center cardButtons teamCardButtons">
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://www.twitch.tv/wilco903">Twitch</NavLink>
                </div>
                <div className="col-12">
                    <NavLink className="recolouredCardButton cardButton twoCardButton teamCardButton" to="https://youtu.be/dQw4w9WgXcQ">YouTube</NavLink>
                </div>
            </div>
        </div>
        </>
    )
}