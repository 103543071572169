import { Helmet } from "react-helmet";
import "../../css/forms.min.css";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

export function Collab() {
    const captchaRef = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById("mod").classList.remove("active")
    }, [])

    function parseForm(formData) {
        let newObj = [
            {
                "Question" : "What is your name?",
                "Answer" : formData['name']
            },
            {
                "Question": "What is your Discord tag?",
                "Answer": formData['discord']
            },
            {
                "Question": "What are your other socials?",
                "Answer": formData['socials']
            },
            {
                "Question": "What is your email?",
                "Answer": formData['email']
            },
            {
                "Question": "Why do you want to collab with me?",
                "Answer": formData['why']
            },
            {
                "Question": "What game(s) would you like to play?",
                "Answer": formData['games']
            },
            {
                "Question": "What date would you like to collab?",
                "Answer": formData['days']
            },
            {
                "Question": "Anything else?",
                "Answer": formData['other']
            }
        ]

        return newObj;
    }

    function checkForm(e) {
        const token = captchaRef.current.getValue();
        if (token) {
            $("#recaptchaValid").prop("checked", true);
        }

        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    e.preventDefault();

                    captchaRef.current.reset();
                    $("#recaptchaValid").prop("checked", false);



                    axios.post(`${process.env.REACT_APP_SERVER_URL}/recaptcha`, {token})
                    .then(res =>  {
                        if (res.status === 200) {
                            var formData = {
                                name: $("#name").val(),
                                discord: $("#discord").val(),
                                socials: $("#socials").val(),
                                email: $("#email").val(),
                                why: $("#why").val(),
                                games: $("#games").val(),
                                days: $("#days").val(),
                                other: $("#other").val(),
                            };

                            let newObj = parseForm(formData);

                            console.log(newObj)

                            axios.post(`${process.env.REACT_APP_SERVER_URL}/collab`, newObj).then(data => {
                                console.log(data)
                                
                                window.scrollTo(0, 0);
                                document.getElementById("formBox").style.display = "none";
                                document.getElementById("success").style.display = "block";
                                document.getElementById('leadPara').style.display = "none";
                            })
                    
                            // $.ajax({
                            //     type: "POST",
                            //     url: "process.env/mod",
                            //     data: newObj,
                            //     dataType: "json",
                            //     encode: true,
                            // }).done(function (data) {
                            //     console.log(data)
                    
                            //     document.getElementById("formBox").style.display = "none";
                            //     document.getElementById("success").style.display = "block";
                            //     document.getElementById('leadPara').style.display = "none";
                            // })
                        }
                    })
                    .catch((error) => {
                    console.log(error);
                    })
                }
            
                form.classList.add('was-validated')
            });
    }
    
    return (
        <>
        <Helmet>
            <meta content="Gulimja - Collaboration Form" property="og:title" />
            <meta content="Come join Guli in the club <3" property="og:description" />
            <meta content="https://www.gulimja.xyz/formBase.php" property="og:url" />
            <meta content="https://cdn.discordapp.com/attachments/785601770104946748/973628030981386240/carrotLogo.png" property="og:image" />
            <meta content="#f5bfcd" data-react-helmet="true" name="theme-color" />
        </Helmet>

        <p id="leadPara" className="lead" style={{color: "white", textAlign: "center"}}>This is the application to collaborate with me! Please ensure that any information you enter is accurate else I won't be able to contact you.</p>
        
        <div className="row formBox" id="formBox">
            <form className="needs-validation" onSubmit={checkForm} noValidate>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">What is your name?*</label>
                    <input type="text" className="form-control" placeholder="Gulimja" name="name" id="name" aria-label="Name input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="discord" className="form-label">What is your Discord tag?*</label>
                    <input type="text" className="form-control" placeholder="Guli#0001" name="discord" id="discord" aria-label="Discord input" required></input>

                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="socials" className="form-label">What are your other socials?*</label>
                    <textarea className="form-control" name="socials" id="socials" rows="4" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">What is your email?*</label>
                    <input type="email" className="form-control" name="email" placeholder="gulimjavt@gmail.com" id="email" aria-label="Email input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="why" className="form-label">Why do you want to collab with me?*</label>
                    <textarea className="form-control" id="why" name="why" rows="4" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="games" className="form-label">What game(s) would you like to play?*</label>
                    <input type="text" className="form-control" name="games" placeholder="Apex Legends" id="games" aria-label="Games input" required></input>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="days" className="form-label">What date(s) would you like to collab?*</label>
                    <textarea className="form-control" id="days" name="days" rows="2" required></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                    <div className="invalid-feedback">
                        This field is required.
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="other" className="form-label">Anything you would like to add?</label>
                    <textarea className="form-control" id="other" name="other" rows="4"></textarea>
                    <div className="valid-feedback">
                        Looking good!
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're happy to submit this data to Guli and their moderation team for review.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="terms" required></input>
                        <label className="form-check-label" htmlFor="terms">Please check to confirm that you're over 18.*</label>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => {$("#recaptchaValid").prop("checked", true);}} onExpired={() => {$("#recaptchaValid").prop("checked", false);}}/>
                        <input className="form-control d-none" type="checkbox" id="recaptchaValid" required ></input>
                        <div className="invalid-feedback">
                            Please complete the ReCaptcha.
                        </div>
                        <div className="valid-feedback">
                            Done.
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary" type="submit">Submit form</button>
                </div>
            </form>
        </div>

        <div className="row formBox justify-content-center" id="success">
            <h1 className="display-1">Success!</h1>
            <p className="lead">Your collaboration application has been successfully submitted!</p>
            <p>Our team will now review your application. We aim to get back to you shortly however we cannot guarentee a timeframe. Please look on your Discord for a friend request from Guli#0001.</p>

            <NavLink className="homeButton funkyColourBorder" to="/" style={{paddingTop: "1.5%", paddingBottom: "1.5%"}}>Home</NavLink>
        </div>
        </>
    )
}

