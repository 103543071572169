import { Outlet } from "react-router-dom";

export function Login() {
    return (
    <>
        <div className="container" style={{minHeight: "58.3vh"}}>
            <img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" alt="Main Logo" className="mainLogo"></img>

            <div className="row nf404 justify-content-center">
                <h1 className="display-1">Logging in...</h1>
            </div>
        </div>

        <Outlet />
    </>
    );
}