import axios from "axios";

export function TwitchLogin() {
    let query = new URLSearchParams(window.location.search);

    if ((localStorage.getItem("state") !== query.get("state")) || query.get("error")) {
        console.log("error");
        localStorage.removeItem("state");
        window.location.replace("http://localhost:3000/dashboard");
    }

    localStorage.removeItem("state");

    let data = {
        code: query.get("code"),
        type: localStorage.getItem("type"),
        token: localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token")
    }

    axios.post(`https://www.gulimja.xyz/connect/twitchLogin`, data).then(res => {
        if (res.status !== 200) console.log("ERROR");

        console.log(res.status);

        let sessionToken = res.statusText !== "OK" ? res.statusText : undefined;

        if (sessionToken) {
            if (localStorage.getItem("remember") === "yes") {
                localStorage.setItem("token", sessionToken);
            } else {
                sessionStorage.setItem("token", sessionToken);
            }
        }

        localStorage.removeItem("type");
        localStorage.removeItem("remember");

        window.location.replace("http://localhost:3000/dashboard");
    })
    
    return (
    <>
        
    </>
    );
}