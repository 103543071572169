import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./CommSelector.css";
import "../css/default.css";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";

export function CommSelector() {
    useEffect(() => {
        window.scrollTo(0, 0)
        if (document.URL.endsWith("/commission")) {
            document.getElementById("mod").classList.add("active")
        }
    }, [])

    const navigate = useNavigate();
    return (
        <>
        <Helmet>
        <script src="https://www.recaptcha.net/recaptcha/api.js" async defer></script>
        </Helmet>
        <div className="container-fluid">
            <div className="container">
                <img src="https://i.ibb.co/pbmTk4d/logo-For-Site.png" alt="Main Logo" className="mainLogo"></img>
                <nav className="nav nav-pills nav-justified formNav">
                    <NavLink id="mod" className="nav-link forms-bar" to="/commission/bot">Bot</NavLink>
                    <NavLink className="nav-link forms-bar" to="/commission/website">Website</NavLink>
                </nav>

                <select className="form-select form-select-lg mb-3" id="select" onInput={() => changePage(navigate)} defaultValue="moderators">
                    <option value="bot">Bot</option>
                    <option value="website">Website</option>
                </select>

                <Outlet />
                
            </div>
        </div>
        </>
    )
}

function changePage(navigate) {
    let toNav = document.getElementById("select").selectedOptions[0].value;
    navigate(`/commission/${toNav}`);
}